import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';


const ZipCodeForm = (props) => {
    const [zipCode, setZipCode] = useState('');
    const [isValid, setIsValid] = useState(true);
    const { handleFormSubmit } = props;

    const formIsValid = () => {
        return zipCode.match(/^\d{5}$/);
    }

    const localHandleFormSubmit = async (event) => {
        event.preventDefault();
        if (formIsValid()) {
            await handleFormSubmit(zipCode);
            setZipCode('');
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    };

    return (
        <Panel header="Add Location">
            <form onSubmit={localHandleFormSubmit}>
                <div className="field">
                    <label htmlFor="zipCode">Please enter any zip code</label>
                    <div className="p-inputgroup">
                        <InputText value={zipCode} onChange={event => setZipCode(event.target.value)} autoFocus required className={`${isValid ? "" : "p-invalid"}`} />
                        <Button type='submit' aria-label="Submit" >Add</Button>
                    </div>
                </div>
                {isValid ? "" :
                    <small id="username2-help" className="p-error block">Let's try a valid zip code (5 digits, no spaces).</small>
                }
            </form>
        </Panel>
    );
}

export default ZipCodeForm;