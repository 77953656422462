import React, { useContext } from 'react';
import WeatherPanel from './WeatherPanel';
import { WeatherStateContext } from './weather-state';


const WeatherPanelList = props => {
    const { zipWeatherDataMap } = useContext(WeatherStateContext); // Could be pulled out to it's own hook
    return (<>
        {Object.entries(zipWeatherDataMap).map(([zipCode, weatherData]) => <WeatherPanel key={zipCode} zipCode={zipCode} weatherData={weatherData} />)}
    </>);
};

export default WeatherPanelList;