import React, { useContext } from 'react';
import { Panel } from 'primereact/panel';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { WeatherStateContext } from './weather-state';
import { WeatherService } from './weather-service-api';

/*
    This component is getting a little bloated. removePanel(), refreshPanel() are handling the manupulation of
    zipWeatherDataMap which could exist on the WeatherService, but I had to call this done at some point.
 */
const WeatherPanel = (props) => {
    const { zipCode, weatherData } = props;
    let { zipWeatherDataMap, setZipWeatherDataMap } = useContext(WeatherStateContext); // Could be pulled out to it's own hook

    const kelvinToFehrenheit = (temp) => Math.round((((temp - 273.15) * 9 / 5) + 32));

    const removePanel = () => {
        delete zipWeatherDataMap[zipCode];
        setZipWeatherDataMap({ ...zipWeatherDataMap });
    };

    const refreshPanel = async () => {
        const weatherData = await WeatherService.getWeatherByZipCode(zipCode, true);
        setZipWeatherDataMap({ ...zipWeatherDataMap, [zipCode]: weatherData });
    }

    const ToolbarHeaderTitle = (
        <React.Fragment>
            <h4>{`${weatherData.name} Weather`}</h4>
        </React.Fragment>
    );

    const ToolbarHeaderButton = (
        <React.Fragment>
            <Button icon="pi pi-refresh" className="mr-2" onClick={refreshPanel} />
            <Button icon="pi pi-times" className="p-button-danger" onClick={removePanel} />
        </React.Fragment>
    );

    const WeatherPanelHeaderTemplate = () => <Toolbar left={ToolbarHeaderTitle} right={ToolbarHeaderButton} />;

    if (!weatherData || !weatherData.main) return null;
    return (<>
        <Panel headerTemplate={WeatherPanelHeaderTemplate}>
            <div className="grid">
                <div className="col">
                    <p>The weather in {weatherData.name} is {kelvinToFehrenheit(weatherData.main.temp)}*F.</p>
                    <p>The high is {kelvinToFehrenheit(weatherData.main.temp_max)}*F and the low is {kelvinToFehrenheit(weatherData.main.temp_min)}*F.</p>
                </div>
                <div className="col">
                    <iframe style={{ 'width': 200, 'height': 170 }}
                        src={`https://maps.google.com/maps?q=${weatherData.coord.lat},${weatherData.coord.lon}&z=13&output=embed`}
                    >
                    </iframe>
                    <br />
                    <small>
                        <a href={`https://maps.google.com/maps?q=${weatherData.coord.lat},${weatherData.coord.lon}&z=13&output=embed`}
                            target="_blank">
                            See map bigger
                        </a>
                    </small>
                </div>
            </div>
        </Panel>
    </>);
};

export default WeatherPanel;