import React, { useContext } from 'react';
import './App.css';
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "/node_modules/primeflex/primeflex.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import WeatherPanelList from './WeatherPanelList';
import ZipCodeForm from './ZipCodeForm';
import { WeatherService } from './weather-service-api';
import { WeatherStateContext } from './weather-state';

function WeatherApp() {
  const { zipWeatherDataMap, setZipWeatherDataMap } = useContext(WeatherStateContext); // Could be pulled out to it's own hook

  // This technically doesn't need to be here, and could live in the ZipCodeForm. 
  const handleFormSubmit = async (zipCode) => {
    if (zipCode) {
      const weatherData = await WeatherService.getWeatherByZipCode(zipCode);
      setZipWeatherDataMap({ ...zipWeatherDataMap, [zipCode]: weatherData });
    }
  };

  return (
    <div className="App">
      <ZipCodeForm handleFormSubmit={handleFormSubmit} />
      <WeatherPanelList />
    </div >
  );
}

export default WeatherApp;
