import { StringLiterals } from './StringLiterals';

export const WeatherService = (() => {
    const API_KEY = 'a8f2b4cb2842f4654448744fa323dd35';
    const API_URL = 'https://api.openweathermap.org/data/2.5/weather?zip=${ZIP_CODE},us&appid=${API_KEY}';

    // template strin at runtime from https://stackoverflow.com/a/55594573
    const templateString = (str, obj) => str.replace(/\${(.*?)}/g, (x, g) => obj[g]);

    const getWeatherByZipCode = async (ZIP_CODE) => {
        if (!ZIP_CODE)
            return await Promise.resolve(undefined);

        return await fetch(templateString(API_URL, { ZIP_CODE, API_KEY }))
            .then(response => response.json());
    };

    const getCachedWeatherData = async (ZIP_CODE, bustCache = false) => {
        async function getNewCacheWeatherData(getWeatherByZipCode, ZIP_CODE, zipWeatherDataMap) {
            let weatherData = await getWeatherByZipCode(ZIP_CODE);
            zipWeatherDataMap[ZIP_CODE] = weatherData;
            window.localStorage.setItem(StringLiterals.WEATHER_DATA, JSON.stringify(zipWeatherDataMap));
            return await Promise.resolve(weatherData);
        }

        let zipWeatherDataMap = {};
        if (window.localStorage.getItem(StringLiterals.WEATHER_DATA)) {
            zipWeatherDataMap = JSON.parse(window.localStorage.getItem(StringLiterals.WEATHER_DATA));
            if (!bustCache && zipWeatherDataMap[ZIP_CODE]) {
                return await Promise.resolve(zipWeatherDataMap[ZIP_CODE]);
            } else {
                return await getNewCacheWeatherData(getWeatherByZipCode, ZIP_CODE, zipWeatherDataMap);
            }
        } else {
            return await getNewCacheWeatherData(getWeatherByZipCode, ZIP_CODE, zipWeatherDataMap);
        }

    };

    const getAllCachedWeatherData = () => window.localStorage.getItem(StringLiterals.WEATHER_DATA) ?
        JSON.parse(window.localStorage.getItem(StringLiterals.WEATHER_DATA)) :
        null;

    return { getWeatherByZipCode: getCachedWeatherData, getAllCachedWeatherData };
})();
