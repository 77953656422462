import { createContext, useState } from "react";
import { WeatherService } from './weather-service-api';

const WeatherStateContext = createContext();

const WeatherStateContextProvider = ({ children }) => {
    const [zipWeatherDataMap, setZipWeatherDataMap] = useState(WeatherService.getAllCachedWeatherData() || {});

    return (
        <WeatherStateContext.Provider value={{ zipWeatherDataMap, setZipWeatherDataMap }}>
            {children}
        </WeatherStateContext.Provider>
    );
};

export { WeatherStateContext, WeatherStateContextProvider };